import React, { useCallback, useEffect, useContext } from "react";
import { AppContext } from "../App";
import KeyButton from "./KeyButton";

function Keyboard() {
  const { onSelectLetter, onDelete, onEnter } = useContext(AppContext);

  const handleKeyPress = useCallback((e) => {
    if (e.key === "Enter") {
      onEnter();
      return;
    }

    if (e.key === "Backspace" || e.key === "Delete") {
      onDelete();
      return;
    }

    if (e.key.match(/^[a-z]$/)) {
      onSelectLetter(e.key);
      return;
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const keys1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
  const keys2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
  const keys3 = ["Z", "X", "C", "V", "B", "N", "M"];

  return (
    <div className="keyboard">
      {keys1.map((key) => {
        return <KeyButton key={key} keyVal={key} />;
      })}

      <div className="space"></div>
      {keys2.map((key) => {
        return <KeyButton key={key} keyVal={key} />;
      })}
      <div className="space"></div>

      <KeyButton key={"ENTER"} keyVal={"ENTER"} bigKey={true} />
      {keys3.map((key) => {
        return <KeyButton key={key} keyVal={key} />;
      })}
      <KeyButton key={"DELETE"} keyVal={"DELETE"} bigKey={true} />
    </div>
  );
}

export default Keyboard;
