import React, { useContext } from "react";
import Modal from '../Modal'
import styled from 'styled-components'
import { AppContext } from '../../App';
import BasicStatsContainer from './BasicStatsContainer';
import EndGameContainer from './EndGameContainer';
import StatDistributionContainer from './StatDistributionContainer';

const Title = styled.h1`
  text-align: center;
  font-size: 3em;
`;

const StatsModal = ({ isShowing, hide }) => {
  const { savedData, gameStatus, maxGuesses } = useContext(AppContext);

  const { gamesPlayed, gamesWon, currentStreak, maxStreak, distribution } = savedData;
  const safeDistribution = distribution == null ? new Map() : distribution

  const winPercentage = (gamesPlayed != null && gamesPlayed > 0) ? (gamesWon / gamesPlayed * 100.0) : 0

  const statsToRender = [
    ["Played", gamesPlayed],
    // ["Won", gamesWon],
    ["Win %", winPercentage.toFixed(0)],
    ["Current Streak", currentStreak],
    ["Max Streak", maxStreak],
  ]

  return (
    <Modal isShowing={isShowing} hide={hide}>
      <Title>Stats</Title>
      <BasicStatsContainer stats={statsToRender} />
      <StatDistributionContainer distribution={safeDistribution} maxGuesses={maxGuesses}/>
      {gameStatus.gameOver && <EndGameContainer />}
    </Modal>
  )
}

export default StatsModal;