import wordleBank from "./shuffled-wordle-bank-isogram.txt";
import sgbWordBank from "./shuffled-sgb-words-isogram.txt";
import dwylWordBank from "./alphabetized-dwyl-5-letters-isogram.txt";
const moment = require("moment-timezone");

export const boardDefault = (wordLength, maxGuesses) => {
  let board = [];

  for (let row = 0; row < maxGuesses; row++) {
    let rowElements = [];

    for (let elem = 0; elem < wordLength; elem++) {
      rowElements.push({
        data: "",
        state: "default",
        stateAfterGame: "default",
        clickedState: "default",
      });
    }

    board.push(rowElements);
  }
  return board;
};

export const cowsAndBullsDefault = (maxGuesses) => {
  let cowsAndBulls = [];
  for (let row = 0; row < maxGuesses; row++) {
    let rowElems = { cows: "", bulls: "", state: "default" };
    cowsAndBulls.push(rowElems);
  }
  return cowsAndBulls;
};

export const isIsogram = (str) => {
  return !/(\w).*\1/i.test(str);
};

export const getIndexOfArrayAccDate = () => {
  const baselineDate = moment.tz("2022-04-20", "America/Los_Angeles"); // Apr 20 PDT
  let currDate = moment.tz("America/Los_Angeles");
  const diffDays = Math.abs(baselineDate.diff(currDate, "days", false));
  return diffDays;
};

export const checkCowsAndBulls = (currWord, correctWord) => {
  // console.log("currWord: ", currWord, "correctWord: ", correctWord)

  let cows = 0;
  let bulls = 0;
  let states = new Array(currWord.length);

  for (let i = 0; i < currWord.length; i++) {
    states[i] = "";
    if (currWord[i] === correctWord[i]) {
      bulls++;
      states[i] = "correct";
    } else if (correctWord.includes(currWord[i])) {
      cows++;
      states[i] = "wrong-location";
    }
  }

  return { cows, bulls, states };
};

export const generateWordSet = async () => {
  let wordSet = new Set();
  let todaysWord;

  await fetch(wordleBank)
    .then((respose) => respose.text())
    .then((result) => {
      const wordArray = result.split("\n");
      let isoWordArray = [];

      wordArray.forEach((word) => {
        if (isIsogram(word)) {
          isoWordArray.push(word.toUpperCase());
        }
      });

      //   todaysWord =
      //     isoWordArray[Math.floor(Math.random() * isoWordArray.length)];

      let todaysIndex = getIndexOfArrayAccDate() % (isoWordArray.length - 1);
      // console.log(todaysIndex);
      todaysWord = isoWordArray[todaysIndex];

      wordSet = new Set(isoWordArray);
      // console.log(todaysWord);
    });

  await fetch(sgbWordBank)
    .then((respose) => respose.text())
    .then((result) => {
      const wordArray = result.split("\n");
      wordArray.forEach((word) => {
        if (isIsogram(word)) {
          wordSet.add(word.toUpperCase());
        }
      });
    });

  await fetch(dwylWordBank)
    .then((respose) => respose.text())
    .then((result) => {
      const wordArray = result.split("\n");
      wordArray.forEach((word) => {
        if (isIsogram(word)) {
          wordSet.add(word.toUpperCase());
        }
      });
    });

  return { wordSet, todaysWord };
};
