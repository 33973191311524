import "./App.css";
import Keyboard from "./components/Keyboard";
import GameOver from "./components/GameOver";
import GuessGrid from "./components/GuessGrid";
import HeaderRow from "./components/HeaderRow";
import {
  boardDefault,
  cowsAndBullsDefault,
  isIsogram,
  checkCowsAndBulls,
  generateWordSet,
  getIndexOfArrayAccDate,
} from "./utils";
import { useState, createContext, useEffect } from "react";
import HowToPlayModal from "./components/HowToPlayModal";
import StatsModal from "./components/statistics/StatsModal";
import useModal from "./hooks/useModal";
import useStickyState from "./hooks/useStickyState";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavBar from "./components/NavBar";
import AdsComponent from "./components/AdsComponent";

export const AppContext = createContext();

const addGuessToDistribution = (d, guess) => {
  d[guess] = (d[guess] != null ? d[guess] : 0) + 1;
  return d;
};

function App() {
  const maxGuesses = 9;
  const displayFullBoard = true;
  const gameDay = getIndexOfArrayAccDate();

  const [wordSet, setWordSet] = useState(new Set());
  const [correctWord, setCorrectWord] = useState("QWERT");
  const [flipRow, setFlipRow] = useState(-1);

  const [board, setBoard] = useState(
    boardDefault(correctWord.length, maxGuesses)
  );
  const [cowsAndBulls, setCowsAndBulls] = useState(
    cowsAndBullsDefault(maxGuesses)
  );
  const [currAttempt, setCurrAttempt] = useState({ rowNum: 0, pos: 0 });
  const [currGuess, setCurrGuess] = useState(1);
  const [gameStatus, setGameStatus] = useState({
    gameInitialized: true,
    gameOver: false,
    guessedWord: false,
  });

  const [savedData, setSavedData] = useStickyState(
    {
      gamesPlayed: 0,
      gamesWon: 0,
      currentStreak: 0,
      maxStreak: 0,
      distribution: new Map(),
    },
    "savedData"
  );
  const [savedGameDay, setSavedGameDay] = useStickyState(-1, "savedGameDay");
  const [savedBoard, setSavedBoard] = useStickyState(board, "savedBoard");
  const [savedCowsAndBulls, setSavedCowsAndBulls] = useStickyState(
    cowsAndBulls,
    "savedCowsAndBulls"
  );
  const [savedCurrAttempt, setSavedCurrAttempt] = useStickyState(
    currAttempt,
    "savedCurrAttempt"
  );
  const [savedCurrGuess, setSavedCurrGuess] = useStickyState(
    currGuess,
    "savedCurrGuess"
  );
  const [savedGameStatus, setSavedGameStatus] = useStickyState(
    gameStatus,
    "savedGameStatus"
  );

  useEffect(() => {
    generateWordSet().then((words) => {
      setWordSet(words.wordSet);
      setCorrectWord(words.todaysWord);

      if (gameDay === savedGameDay) {
        setBoard(savedBoard);
        setCowsAndBulls(savedCowsAndBulls);
        setCurrAttempt(savedCurrAttempt);
        setCurrGuess(savedCurrGuess);
        setGameStatus(savedGameStatus);

        if (savedGameStatus.gameOver && savedGameStatus.guessedWord) {
          toggleStatisticsModal();
        }
      } else {
        let newGameStatus = {
          gameInitialized: true,
          gameOver: false,
          guessedWord: false,
        };

        setGameStatus(newGameStatus);
        setSavedGameStatus(newGameStatus);

        if (gameDay !== savedGameDay + 1) {
          setSavedData({
            gamesPlayed: savedData.gamesPlayed,
            gamesWon: savedData.gamesWon,
            currentStreak: 0,
            maxStreak: savedData.maxStreak,
            distribution: savedData.distribution,
          });

          setSavedBoard(null);
          setSavedCowsAndBulls(null);
          setSavedCurrAttempt(null);
          setSavedCurrGuess(null);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Runs once

  const [isShowingHowToPlayModal, toggleHowToPlayModal] = useModal();
  const [isShowingStatisticsModal, toggleStatisticsModal] = useModal();

  const [shakingRow, setShakingRow] = useState(-1);

  const [eliminatedLetters, setEliminatedLetters] = useState(new Set());
  const [possibleLetters, setPossibleLetters] = useState(new Set());

  const gameActive = gameStatus.gameInitialized && !gameStatus.gameOver;

  /*---------------------------------------*/
  /*------START OF INTERACTIONS -----------*/
  /*---------------------------------------*/

  const onTileClick = (pos, rowNum) => {
    if (!gameActive) {
      return;
    }

    if (board[rowNum][pos].data === "") {
      return;
    }
    
    const currClickedState = board[rowNum][pos].clickedState

    let newClickedState = "default"
    if (currClickedState === "default") {
      newClickedState = "wrong-location"
    }
    else if (currClickedState === "wrong-location") {
      newClickedState = "correct" 
    }
    else if (currClickedState === "correct") {
      newClickedState = "wrong"
    }

    const newBoard = [...board];
    newBoard[rowNum][pos].clickedState = newClickedState
    setBoard(newBoard);
  };

  const onSelectLetter = (keyVal) => {
    if (!gameActive) {
      return;
    }
    if (currAttempt.pos > correctWord.length) {
      return;
    }
    const newBoard = [...board];
    newBoard[currAttempt.rowNum][currAttempt.pos].data = keyVal.toUpperCase();
    newBoard[currAttempt.rowNum][currAttempt.pos].state = "active";
    newBoard[currAttempt.rowNum][currAttempt.pos].stateAfterGame = "active";
    setBoard(newBoard);
    setCurrAttempt({ ...currAttempt, pos: currAttempt.pos + 1 });
  };

  const onDelete = () => {
    if (!gameActive) {
      return;
    }
    if (currAttempt.pos === 0) {
      return;
    }
    const newBoard = [...board];
    newBoard[currAttempt.rowNum][currAttempt.pos - 1].data = "";
    newBoard[currAttempt.rowNum][currAttempt.pos - 1].state = "default";
    newBoard[currAttempt.rowNum][currAttempt.pos - 1].clickedState = "default";
    setBoard(newBoard);
    setCurrAttempt({ ...currAttempt, pos: currAttempt.pos - 1 });
  };

  const onEnter = () => {
    if (!gameActive) {
      return;
    }
    if (currAttempt.pos < correctWord.length) {
      toast.error("Not enough letters!");
      setShakingRow(currAttempt.rowNum);
      return;
    }

    let currWord = "";
    for (let i = 0; i < correctWord.length; i++) {
      currWord += board[currAttempt.rowNum][i].data;
    }
    currWord = currWord.toUpperCase();

    if (!isIsogram(currWord)) {
      toast.error("Word cannot have repeated letters!");
      setShakingRow(currAttempt.rowNum);
      return;
    }

    if (!wordSet.has(currWord)) {
      toast.error("Word not found in dictionary!");
      setShakingRow(currAttempt.rowNum);
      return;
    }

    const cb = checkCowsAndBulls(currWord, correctWord);
    const newEliminatedLettersSet = new Set(eliminatedLetters);
    const newPossibleLettersSet = new Set(possibleLetters);

    const currWordArray = currWord.split("");
    if (cb.cows === 0 && cb.bulls === 0) {
      currWordArray.forEach((l) => {
        newEliminatedLettersSet.add(l);
        if (possibleLetters.has(l)) {
          newPossibleLettersSet.delete(l);
        }
      });
      setEliminatedLetters(newEliminatedLettersSet);
      setPossibleLetters(newPossibleLettersSet);
    } else {
      currWordArray.forEach((l) => {
        if (!eliminatedLetters.has(l)) {
          newPossibleLettersSet.add(l);
        }
      });
      setPossibleLetters(newPossibleLettersSet);
    }

    const newBoard = [...board];
    for (let row = 0; row <= currAttempt.rowNum; row++) {
      for (let i = 0; i < currWord.length; i++) {
        if (newEliminatedLettersSet.has(board[row][i].data)) {
          newBoard[row][i].state = "wrong";
          newBoard[row][i].stateAfterGame = "wrong";
        }

        // For the current row, set states (correct, wrong location) to be shown after the game
        if (row === currAttempt.rowNum && cb.states[i] !== "") {
          newBoard[row][i].stateAfterGame = cb.states[i];
        }
      }
    }
    setBoard(newBoard);
    setFlipRow(currAttempt.rowNum);

    // gtag analytics
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'Gameplay',
        action: 'Guess',
        label: currWord,
      }
    });

    const newCowsAndBulls = [...cowsAndBulls];
    newCowsAndBulls[currAttempt.rowNum].cows = cb.cows;
    newCowsAndBulls[currAttempt.rowNum].bulls = cb.bulls;
    newCowsAndBulls[currAttempt.rowNum].state = "calc";
    setCowsAndBulls(newCowsAndBulls);

    let isGameOverNow = false;
    let gameWon = false;
    if (currWord === correctWord) {
      isGameOverNow = true;

      const newGameStatus = {
        gameInitialized: true,
        gameOver: isGameOverNow,
        guessedWord: true,
      };
      setGameStatus(newGameStatus);
      setSavedGameStatus(newGameStatus);

      gameWon = true;

      toggleStatisticsModal();
    } else if (currAttempt.rowNum === maxGuesses - 1) {
      isGameOverNow = true;

      const newGameStatus = {
        gameInitialized: true,
        gameOver: isGameOverNow,
        guessedWord: false,
      };
      setGameStatus(newGameStatus);
      setSavedGameStatus(newGameStatus);
    } else {
      const attempt = { rowNum: currAttempt.rowNum + 1, pos: 0 };
      const guess = currGuess + 1;

      setCurrAttempt(attempt);
      setCurrGuess(guess);

      setSavedCurrAttempt(attempt);
      setSavedCurrGuess(guess);
    }

    if (isGameOverNow) {
      const newBoard = [...board];
      for (let row = 0; row < maxGuesses; row++) {
        for (let i = 0; i < correctWord.length; i++) {
          newBoard[row][i].state = newBoard[row][i].stateAfterGame;
        }
      }
      setBoard(newBoard);

      const safeDistribution =
        savedData.distribution == null ? new Map() : savedData.distribution;

      if (gameWon) {
        const continueStreak = gameDay === savedGameDay + 1;
        const currentStreak = continueStreak ? savedData.currentStreak + 1 : 1;
        const maxStreak =
          currentStreak > savedData.maxStreak
            ? savedData.maxStreak + 1
            : savedData.maxStreak;

        setSavedData({
          gamesPlayed: savedData.gamesPlayed + 1,
          gamesWon: savedData.gamesWon + 1,
          currentStreak: currentStreak,
          maxStreak: maxStreak,
          distribution: addGuessToDistribution(safeDistribution, currGuess),
        });

        // gtag analytics
        window.dataLayer.push({
          event: 'event',
          eventProps: {
            category: 'Gameplay',
            action: 'Win',
            label: correctWord,
          }
        });
      } else {
        setSavedData({
          gamesPlayed: savedData.gamesPlayed + 1,
          gamesWon: savedData.gamesWon,
          currentStreak: 0,
          maxStreak: savedData.maxStreak,
          distribution: safeDistribution,
        });

        // gtag analytics
        window.dataLayer.push({
          event: 'event',
          eventProps: {
            category: 'Gameplay',
            action: 'Lose',
            label: correctWord,
          }
        });
      }

      // TODO:
      // setTimeout(3000);
      // toggleStatisticsModal();
    }

    setSavedGameDay(gameDay);
    setSavedBoard(newBoard);
    setSavedCowsAndBulls(newCowsAndBulls);
  };
  /*---------------------------------------*/
  /*------END OF INTERACTIONS -------------*/
  /*---------------------------------------*/

  return (
    <div className="App">
      <NavBar
        toggleHowToPlayModal={toggleHowToPlayModal}
        toggleStatisticsModal={toggleStatisticsModal}
      />
      <AppContext.Provider
        value={{
          correctWord,
          currGuess,
          setCurrGuess,
          maxGuesses,
          displayFullBoard,
          board,
          setBoard,
          currAttempt,
          setCurrAttempt,
          onTileClick,
          onSelectLetter,
          onDelete,
          onEnter,
          cowsAndBulls,
          setCowsAndBulls,
          gameStatus,
          eliminatedLetters,
          setEliminatedLetters,
          possibleLetters,
          setPossibleLetters,
          shakingRow,
          setShakingRow,
          flipRow,
          setFlipRow,
          savedData,
        }}
      >
        <HeaderRow />
        <ToastContainer
          theme="colored"
          position="top-center"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
        />
        <GuessGrid />
        <div>{gameStatus.gameOver ? <GameOver /> : <Keyboard />}</div>
        <HowToPlayModal
          isShowing={isShowingHowToPlayModal}
          hide={toggleHowToPlayModal}
        />
        <StatsModal
          isShowing={isShowingStatisticsModal}
          hide={toggleStatisticsModal}
        />

        <div className="practiceMode">
          <AdsComponent slot="2514824375" />
        </div>
      </AppContext.Provider>
    </div>
  );
}

export default App;
