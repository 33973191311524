import React, { useContext } from 'react'
import Tile from "./Tile";
import { AppContext } from '../App'
import CowBullTile from "./CowBullTile";

function TileRow({ rowNum }) {
  const { correctWord, shakingRow, setShakingRow } = useContext(AppContext);

  const correctWordLength = correctWord.length;
  const correctWordLengthArray = Array.from(Array(correctWordLength).keys());

  const rowKey = "rowNum-" + rowNum;

  let tiles = correctWordLengthArray.map((i) => {
    let key = rowKey + "-pos-" + i;
    return <Tile shake={rowNum === shakingRow ? "yes" : "no"} key={key} pos={i} rowNum={rowNum} />;
  });

  return (
    <div className="tileRow" onAnimationEnd={() => { setShakingRow(-1) }}>
      {tiles}
      <div className="space"></div>
      <CowBullTile isCow={true} key={"c-"+rowNum} rowNum={rowNum}></CowBullTile>
      <CowBullTile key={"b-"+rowNum} rowNum={rowNum}></CowBullTile>
    </div>
  );
}

export default TileRow;
