import React, { useContext } from "react";
import Modal from './Modal'
import styled from 'styled-components'
import { AppContext } from "../App";

const BaseFont = styled.div`
  font-size: 1.0em;
  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 1.25em;
  }
  @media (min-width: 1365px) {
    font-size: 1.75em;
  }
`

const H1 = styled.h1`
  text-align: center;
  font-size: 3.0em;
`;

const H2 = styled.h2`
  text-align: center;
  font-size: 2.0em;
`;

const P = styled(BaseFont)`
  padding: .5em;
`

const HowToPlayModal = ({ isShowing, hide }) => {

  const { maxGuesses } = useContext(AppContext);

  return (
    <Modal isShowing={isShowing} hide={hide}>
      <H1>How to play</H1>

      <P>Guess the <strong>MOORDLE</strong> in {maxGuesses} tries.</P>

      <P>Each guess must be a valid 5-letter word, with no duplicate letters. Hit enter button to submit.</P>

      <P>After each guess, you will see how many <strong>COW</strong>s and <strong>BULL</strong>s your guess contained.</P>

      <P>A <strong>COW 🐮</strong> means that a correct letter exists in your guess, but not in the right position.</P>

      <P>A <strong>BULL 🎯</strong> means that a correct letter is in the correct position in your guess.</P>

      {/* <br />

      <H2>Example</H2>

      <P>TODO: Coming soon!</P> */}
    </Modal>
  )
}

export default HowToPlayModal;