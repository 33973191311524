import { useEffect } from "react";

const AdsComponent = ({ slot }) => {
  const clientId = "ca-pub-3380394685856819"

  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (err) {
      console.error(err)
    }
  }, [ slot ])


  return (
    <div key={slot}>
      <ins
        className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client={clientId}
        data-ad-slot={slot}
        data-ad-format="auto"
        data-full-width-responsive="true"
      >
      </ins>
    </div>
  )
}

export default AdsComponent