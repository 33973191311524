import React, { useContext } from "react";
import { AppContext } from "../App";

function HeaderRow() {
  const { maxGuesses, currGuess, gameStatus, displayFullBoard } = useContext(AppContext);
  const numGuessesLeft = maxGuesses - currGuess + 1;

  const cowsAndBullsWikiLink =
    "https://en.wikipedia.org/wiki/Bulls_and_Cows#:~:text=Bulls%20and%20Cows%20(also%20known,the%20hit%20word%20game%20Wordle.";
  const wordleLink = "https://www.nytimes.com/games/wordle/index.html";

  let heading = "";
  if (!displayFullBoard && !gameStatus.gameOver) {
    heading += "You have " + numGuessesLeft + " tries left";
  }

  return (
    <div className="headerRow">
      <div className="headerRow heading">
        <div>
          Inspired by <a href={wordleLink}> Wordle</a> +{" "}
          <a href={cowsAndBullsWikiLink}> Cows &amp; Bulls</a>
        </div>
        <div>{heading}</div>
      </div>
      <div className="space"></div>
      <div className="headerRow cowImg">🐮</div>
      <div className="headerRow bullImg">🎯</div>
    </div>
  );
}

export default HeaderRow;
