import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../App'

function GameOver() {
    const { gameStatus, correctWord, currAttempt } = useContext(AppContext)

    return (
        <div className='gameOver'>
            <h3>
                {gameStatus.guessedWord ? "You correctly guessed the word!" : "Game over! You don't have any more tries left"}
            </h3>
            <h1>Correct word: {correctWord}</h1>
            {gameStatus.guessedWord && (<h3>You guessed in {currAttempt.rowNum + 1} attempts!</h3>)}
        </div>
    )
}

export default GameOver