import React from "react"
import styled from 'styled-components'

import aboutIcon from '../icons/about-icon.png'
import statsIcon from '../icons/stats-icon.png'

const Icon = styled.img`
  width: 3em;
  height: 3em;
  margin: 1em;
  cursor: pointer;
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6em;
  user-select: none;
  border-bottom: 0.25em solid var(--color-active);
  margin-bottom: 1em;
`

const Title = styled.h1`
  font-size: 4em;
`

const NavBar = ({ toggleHowToPlayModal, toggleStatisticsModal }) => {
  return (
    <StyledNav>
      <Icon src={aboutIcon} alt="about-icon" onClick={() => toggleHowToPlayModal()} />
      <Title>moo-rdle</Title>
      <Icon src={statsIcon} alt="stats-icon" onClick={() => toggleStatisticsModal()} />
    </StyledNav>
  )
}

export default NavBar;