import React, { useContext } from 'react'
import { AppContext } from '../App'

function CowBullTile({ isCow, rowNum }) {

  const { cowsAndBulls } = useContext(AppContext);

    let value = ""
    value = isCow ? cowsAndBulls[rowNum].cows : cowsAndBulls[rowNum].bulls

    let state = cowsAndBulls[rowNum].state
    if (cowsAndBulls[rowNum].state === "calc") {
      if (value === 0) {
        state +="-zero"
      }
      else {
        isCow ? state+="-cow" : state+="-bull"
      }
    }

  return (
    <div className="cowBullTile"  data-state={state}>{value}</div>
  )
}

export default CowBullTile