import { useState } from "react"

const useModal = () => {
  const [isShowingModal, setIsShowingModal] = useState(false)

  const toggleModal = () => { setIsShowingModal(!isShowingModal) }

  return [
    isShowingModal,
    toggleModal,
  ]
}

export default useModal