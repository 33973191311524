import React, { useContext, useEffect, useState } from "react"
import styled from 'styled-components'
import { AppContext } from '../../App'
import { getIndexOfArrayAccDate } from '../../utils'
import { ReactComponent as ShareSvg } from '../../icons/share.svg'
import { toast } from "react-toastify";

const moment = require('moment-timezone')

const StyledEndGameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1em;
`;

const NextMoordleDiv = styled.div`
  flex-grow: 1;
  padding: 1em;
  border-right: 1px solid white;
  text-align: center;
`;

const ShareDiv = styled.div`
  flex-grow: 1;
  padding: .75em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ShareButton = styled.button`
  text-decoration: none;
  background: var(--color-correct);
  padding: 0.75em 2.0em;
  margin: .5em;
  overflow: hidden;

  border: 0.1em solid var(--color-correct);
  border-radius: 1.5em;

  display: flex;
  font-size: 1.5em;
  font-weight: bold;
  white-space: nowrap;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--color-correct);
  }
`;

const ShareIcon = styled(ShareSvg)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: .5em;
  width: 1.75em;
  height: 1.75em;
`

const getTimeToEndOfDay = () => {
  let currDate = moment.tz("America/Los_Angeles");
  let currDateEndOfDay = moment.tz("America/Los_Angeles").endOf('day');
  const diffTimer = moment.utc((currDateEndOfDay).diff(currDate)).format("HH:mm:ss")
  return diffTimer
}

const boardToEmojis = (board, correctWord, currAttempt, gameWon, maxGuesses, charSet) => {
  const day = getIndexOfArrayAccDate()
  const attempts = currAttempt.rowNum + 1
  const gameWonEmoji = gameWon ? '🎉' : ''

  const firstLine = `🐮 🎯 moo-rdle.com #${day} ${attempts}/${maxGuesses} ${gameWonEmoji}\n`

  let result = ""

  result += firstLine
  result += "\n"

  for (let row = 0; row < attempts; ++row) {
    for (let col = 0; col < correctWord.length; ++col) {
      switch (board[row][col].state) {
        case "wrong-location": result += charSet[1]; break;
        case "correct":        result += charSet[2]; break;
        default:               result += charSet[0]; break;
      }
      result += " "
    }
    result += "  \n"
  }

  return result
}

const copyToClipboard = async textToCopy => {
  try {
    await navigator.clipboard.writeText(textToCopy)
  } catch (err) {
    console.log(`Copy failed!`)
  }
}

const shareResults = async (textToCopy) => {
  // gtag analytics
  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category: 'Activity',
      action: 'Share Game',
    }
  });

  if (navigator.share) {
    await navigator.share({ text: textToCopy })
  } else {
    await copyToClipboard(textToCopy)

    toast.info("Copied!");
  }
}

const EndGameContainer = () => {
  const { board, correctWord, currAttempt, gameStatus, maxGuesses } = useContext(AppContext)

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => { clearInterval(timer) }
  }, [])


  // eslint-disable-next-line no-unused-vars
  const [today, setDate] = useState(new Date())

  const timeLeft = getTimeToEndOfDay()

  const gameWon = gameStatus.guessedWord

  const contentVariations = [
    boardToEmojis(board, correctWord, currAttempt, gameWon, maxGuesses, ['⬛', '🟨', '🟩']),
  ]

  return (
    <StyledEndGameContainer>
      <NextMoordleDiv>
        <h2>NEXT MOORDLE</h2>
        <h2>{timeLeft}</h2>
      </NextMoordleDiv>
      <ShareDiv>
        <ShareButton onClick={() => shareResults(contentVariations[0])}>SHARE <ShareIcon /></ShareButton>
      </ShareDiv>
    </StyledEndGameContainer>
  )
}

export default EndGameContainer
