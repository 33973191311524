import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from "styled-components"

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #333;
  opacity: .75;
`

const ModalDialog = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
`

const StyledModalWrapper = styled.div`
  z-index: 100;
  background: var(--color-app);
  position: relative;
  margin: 2.0rem auto;
  border-radius: 1em;
  max-width: 75%;
  min-width: 2em;
  padding: 2em;
  opacity: .80;
`

const ModalClose = styled.div`
  position: absolute;
  font-size: 2em;
  font-weight: 900;
  top: 1em;
  right: 1em;
  cursor: pointer;
  min-width: 1.75em;
  min-height: 1.75em;
  text-align: center;
`

// We use this ModalWrapper to check for clicks outside of the modal to close it down.
const ModalWrapper = ({ hide, children }) => {
  const ref = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {

      if (ref.current && !ref.current.contains(event.target)) {
        hide && hide()
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [hide])

  return (
    <StyledModalWrapper ref={ref} >
      {children}
    </StyledModalWrapper>
  )
}

const Modal = ({ isShowing, hide, children }) => {
  if (!isShowing) {
    return null
  }

  const fragment =
    <React.Fragment>
      <ModalOverlay/>
      <ModalDialog aria-modal aria-hidden tabIndex={ -1 } role="dialog">
        <ModalWrapper hide={hide}>
          <ModalClose onClick={hide}>X</ModalClose>
          {children}
        </ModalWrapper>
      </ModalDialog>
    </React.Fragment>

  return ReactDOM.createPortal(fragment, document.body)
}

export default Modal