import React, { useContext } from 'react'
import { AppContext } from '../App'
import TileRow from "./TileRow";

function GuessGrid() {
  const { maxGuesses, currGuess, displayFullBoard } = useContext(AppContext)

  let numRows;
  if (displayFullBoard) {
    numRows = maxGuesses
  }
  else {
    numRows = currGuess
  }
  
  const numRowsArray = Array.from(Array(numRows).keys())

  let tileRows = numRowsArray.map((i) => {
    let key = "tileRowNum-" + i
    return <TileRow key={key} rowNum={i} />
  });

  return <div className="guessGrid">{tileRows}</div>
}

export default GuessGrid;
