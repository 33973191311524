import React from "react";
import styled from 'styled-components'

const StyledBasicStats = styled.div`
  margin: 1em 5em;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1em;
  justify-content: center;
`;

const FlexFont = styled.div`
  text-align: center;
  font-size: 1.25em;
  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 1.5em;
  }
  @media (min-width: 1365px) {
    font-size: 2em;
  }
  min-height: 2.5em;
  min-width: 3em;
`

const StatDiv = ({ title, stat }) => {
  return (
    <StatContainer key={title}>
      <FlexFont>{stat}</FlexFont>
      <FlexFont>{title}</FlexFont>
    </StatContainer>
  )
}

const BasicStatsContainer = ({ stats }) => {
  return (
    <StyledBasicStats>
      {stats.map(([title, stat]) => <StatDiv key={title} title={title} stat={stat} />)}
    </StyledBasicStats>
  )
}

export default BasicStatsContainer