import React from "react"
import styled from 'styled-components'

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  text-align: center;
  font-size: 2.0em;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 24em;
  justify-content: center;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: .5em;
`

const GreenBar = styled.div`
  background-color: darkgreen;
  height: ${props => `${props.count / props.max * 100.0}%`};
  min-width: 2.0em;
  min-height: 2.0em;
  padding: .5em;
  text-align: center;
`

const Guess = styled.div`
  text-align: center;
  padding: .5em;
`

const Stat = ({ guess, count, max }) => {
  return (
    <Row key={guess}>
      <GreenBar count={count} max={max}>{count}</GreenBar>
      <Guess>{guess}</Guess>
    </Row>
  )
}

const fillDistributionMap = (maxGuesses, distribution) => {
  const fullMap = {}

  const indices = [...Array(maxGuesses).keys()]

  indices.forEach(i => fullMap[i + 1] = 0)

  Object.entries(distribution).forEach(([k, v]) => fullMap[parseInt(k)] = v)

  return fullMap
}

const StatDistributionContainer = ({ distribution, maxGuesses }) => {
  const maxCount = Object.entries(distribution).reduce((prev, curr) => Math.max(prev, curr[1]), 0)

  const fullMap = fillDistributionMap(maxGuesses, distribution)

  return (
    <StatContainer>
      <Title>Guess Distribution</Title>
      <ColumnContainer>
        {Object.entries(fullMap).map(([guess, count]) => <Stat key={guess} guess={guess} count={count} max={maxCount} />)}
      </ColumnContainer>
    </StatContainer>
  )
}

export default StatDistributionContainer;
