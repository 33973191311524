import React, { useContext } from "react";
import { AppContext } from "../App";

function Tile({ pos, rowNum, shake }) {
  const { board, flipRow, setFlipRow, onTileClick } = useContext(AppContext);

  const letter = board[rowNum][pos].data;
  const state = board[rowNum][pos].state;
  const clickedState = board[rowNum][pos].clickedState;

  const shouldFlip = flipRow === rowNum;

  const handleMouseClick = () => {
    onTileClick(pos, rowNum);
  };

  return (
    <div
      className="tile"
      flip={shouldFlip ? "yes" : "no"}
      onTransitionEnd={() => {
        setFlipRow(-1);
      }}
      shake={shake}
      data-state={state}
      clicked-state={clickedState}
      onClick={handleMouseClick}
    >
      {letter}
    </div>
  );
}

export default Tile;
